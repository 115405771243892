export const moviesApiConfig = {
    url: "https://api.nomoreparties.co/beatfilm-movies",

    headers: {
        'Content-Type': 'application/json'
    }
}

export const apiConfig = {
    url: "https://api.foxalice.nomoredomains.work",

    headers: {
        'Content-Type': 'application/json'
    }
}

export const SCREEN_SM = 320;
export const SCREEN_MD = 768;
export const SCREEN_LG = 1141;
export const SCREEN_XL = 1280;
export const SCREEN_XXL = 1400;

export const NUM_SM = 5;
export const NUM_MD = 8;
export const NUM_LG = 9;
export const NUM_XL = 16;

export const MORE_SM = 1;
export const MORE_MD = 2;
export const MORE_LG = 3;
export const MORE_XL = 4;

export const shortFilmsDuration = 40;
